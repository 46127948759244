<script>
export default {
  name: 'Modal',

  props: {
    title: {
      type: String,
      default: ''
    },

    autoHeight: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpen: false
    };
  },

  methods: {
    openModal() {
      this.isOpen = true;
      this.$emit('modal-opened');
    },

    closeModal() {
      this.isOpen = false;
      this.$emit('modal-closed');
    },

    closeModalOutside(event) {
      if (event.target.classList.contains('custom-modal')) {
        this.closeModal();
      }
    }
  }
};
</script>

<template>
  <div>
    <div v-if="isOpen" class="custom-modal" @click.self="closeModalOutside">
      <div class="custom-modal-bg"/>
      <div class="custom-modal-content" :class="autoHeight ? 'custom-modal-content-auto' : ''">
        <div class="custom-modal-header d-flex justify-content-between" ref="header">
          <div class="custom-modal-title">
            {{ title }}
          </div>
          <div class="custom-modal-icon d-flex align-content-center justify-content-center" @click="closeModal">
            <img src="@/assets/icons/close.svg" alt="Close Modal Icon" class="custom-modal-close">
          </div>

        </div>
        <div class="custom-modal-body" v-if="$slots['body']" ref="body">
          <slot name="body"/>
        </div>
        <div class="custom-modal-footer" v-if="$slots['footer']" ref="footer">
          <slot name="footer"/>
        </div>
      </div>

    </div>
  </div>
</template>

<style>
.custom-modal {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  right: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.custom-modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(61, 67, 74, 0.5);
  backdrop-filter: blur(10px);
}

.custom-modal-body {
  height: 100%;
  overflow: auto !important;
}

.custom-modal-icon {
  cursor: pointer;
}

.custom-modal-footer, .custom-modal-body {
  padding: 20px;
}

.custom-modal-footer {
  border-top: 1px solid rgba(210, 214, 218, 1);
}

.custom-modal-header {
  padding: 20px;
  border-bottom: 1px solid rgba(210, 214, 218, 1);
}

.custom-modal-title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(61, 67, 74, 1);
  line-height: normal;
}

.custom-modal-content {
  position: absolute;
  top: 50%;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(246, 248, 249, 1);
  border-radius: 5px;
  width: 50%;
  height: calc(100% - 200px);
}

.custom-modal-content-auto {
  height: auto !important;
}
</style>
