<script>

export default {
  name: 'CustomCard',
  components: {},

  data() {
    return {
      footerUsed: false,
      headerUsed: false
    }
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    dataClass: {
      type: String,
      default: ''
    },
  },

  mounted() {
    if (this.$slots["footer"]) this.footerUsed = true;
  },

  beforeUnmount() {
  },

  methods: {}
}

</script>

<template>
  <div>
    <div class="custom-card-container my-3" ref="container">
      <div class="custom-card-header row g-0 w-100 align-items-center">
        <div class="col-lg-6">
          <span class="custom-card-header-title">{{ title }}</span>&nbsp;
          <span class="custom-card-header-subtitle">{{ subTitle }}</span>
        </div>
        <div class="col-lg-6 d-flex justify-content-end gap-2">
          <slot name="headerRight"/>
        </div>
      </div>

      <div class="custom-card-body" :class="dataClass" ref="body">
        <slot name="data"/>
      </div>
    </div>
  </div>
</template>
<style scoped>

.custom-card-container {
  border-radius: 5px;
  background: rgba(246, 248, 249, 1);

  box-shadow: 0 4px 20px -15px rgba(59, 62, 64, 0.5);
}

.custom-card-body {
  padding: 10px;
}

.custom-card-footer {
  padding: 10px;
}

.custom-card-header {
  padding: 0 10px;
  height: 55px;

  border-bottom: 1px solid rgba(210, 214, 218, 1);
}

.custom-card-header-title {

  padding-left: 10px;

  font-weight: 400;
  font-size: 20px;
  color: rgba(61, 67, 74, 1);
}

.custom-card-header-subtitle {
  font-weight: 400;
  font-size: 20px;
  color: rgba(61, 67, 74, 0.5);
}

</style>