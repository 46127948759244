
const errors = new Map([
    [ "AuthenticationErrors", new Map([])],

    [ "UserSessionErrors", new Map([
        [0, "Sesja wygasła!"],
        [1, "Błędny e-mail lub hasło!"],
        [2, "Błędna struktura danych!"]
    ])
    ],

    [ "UserVerificationErrors", new Map([
        [0, "Błędny format kodu!"],
        [1, "Kod wygasł!"],
        [2, "Błędny kod!"],
        [3, "Konto jest już zweryfikowane!"],
    ])
    ],

    [ "UserErrors", new Map([
        [0, "Nie znaleziono konta!"],
        [1, "Błędna struktura danych!"],
        [2, "Konto z takim e-mailem już istnieje!"],
        [3, "Błędny e-mail"],
        [4, "Błędne hasło"],
        [5, "Błędny numer telefonu!"],
        [6, "Stare hasło nie zgadza się!"],
    ])
    ],

    [ "SmsErrors", new Map([
        [0, "Nieudało się wysłać SMSa"]
    ])
    ],

]);

const ignoredErrors = new Map([
    [ "AuthenticationErrors", [0, 1, 2, 3] ]
]);

function catchErrors(error) {
    if (process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development") {
        console.log(error)
    }

    let messageError = error.message;
    if (error.response) {
        // if (error.response.status === 502) {
        //     routerHelper.push("/maintenance")
        //     return ""
        // }

        try {
            const json = JSON.parse(JSON.stringify(error.response.data));
            if (json !== null) {
                const error = json.error;

                if (error !== null) {
                    const type = error.type;
                    const errorCode = error.code;

                    if ('type' in error && 'code' in error) {
                        // if (type === 'UserVerificationErrors' && errorCode === 3) {
                        //     setTimeout(() => {redirect()}, 450)
                        // }

                        const mapErrors = errors.get(type)
                        if (mapErrors) {
                            messageError = mapErrors.get(errorCode)
                        } else if (error.message) {
                            messageError = error.message
                        } else {
                            messageError = `${type} - ${errorCode}`
                        }

                        const mapIgnoredErrors = ignoredErrors.get(type)
                        if (mapIgnoredErrors && mapIgnoredErrors.includes(errorCode)) {
                            return;
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    this.$snackbar.add({
        type: "error",
        text: messageError
    });

    return messageError
}

function getError(error) {
    if (error.response) {
        try {
            const json = JSON.parse(JSON.stringify(error.response.data));
            if (json !== null) {
                const error = json.error;

                if (error !== null) {
                    if ('type' in error && 'code' in error) {
                        const type = error.type;
                        const errorCode = error.code;

                        return {type: type, code: errorCode, message: error.message}
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return null
}

export { getError, catchErrors }