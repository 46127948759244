<script>
export default {
  name: "Input",
  props: {
    value: {
      validator: value => value === null || typeof value === 'string' || typeof value === 'undefined' || typeof value === 'number',
      required: true
    },

    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      internalValue: this.value,
    }
  },

  watch: {
    value: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalValue = newValue
      }
    },

    internalValue: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("update:value", newValue);
      }
    }
  },

  // methods: {
  //   handleInput(event) {
  //     this.$emit('update:modelValue', event.target.value);
  //   },
  // }
};
</script>

<template>
  <div class="custom-input-container py-1">
    <div class="custom-input-label" v-if="label">{{ label }}</div>
    <div class="custom-input-box">
      <input
          class="custom-input"
          v-model="internalValue"
          :class="error ? 'input-error' : ''"
          :type="type"
          :placeholder="placeholder"
          :disabled="disabled"
      >
      <img src="@/assets/icons/warning.svg" alt="Warning icon" class="input-warning" v-if="error">
    </div>

    <div class="custom-input-error" v-if="error">{{ errorMessage }}</div>
  </div>
</template>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.custom-input-container {
  font-weight: 400;
  font-size: 12px;
}

.custom-input-box {
  position: relative;
}

.input-warning {
  position: absolute;
  right: 10px;
  top: 50%;

  transform: translateY(-50%);

  pointer-events: none;
}

.input-error {
  border-color: rgba(235, 37, 37, 1) !important;
}

.custom-input-error {
  font-size: 10px;
  color: rgba(235, 37, 37, 1);
  margin-top: 5px;
  line-height: normal;
}

.custom-input {
  width: 100%;
  height: 40px;
  padding: 0 35px 0 10px;

  background: rgb(227, 230, 232, 0.25);
  border: 1px solid rgba(106, 114, 123, 0.25);
  border-radius: 5px;

  color: rgba(106, 114, 123, 1);

  transition: all 0.3s ease-in-out;
}

.custom-input::placeholder {
  color: rgba(106, 114, 123, 0.4);
}

.custom-input:not(:placeholder-shown) {

}

.custom-input:hover {
  border-width: 2px;
}

.custom-input:focus {
  border-color: rgba(37, 132, 235, 1);
}

input:focus {
  outline: none;
}

.custom-input-label {
  font-size: 10px;
  color: rgba(61, 67, 74, 1);
  display: block;
}
</style>
