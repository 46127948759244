import { defineStore } from "pinia";

export const useUserStore = defineStore({
  id: "user",
  persist: true,

  state: () => ({
    user: null,
  }),

  actions: {
    setUser(payload) {
      this.user = payload
    }
  }

});