<script>
import { Vue3Snackbar } from "vue3-snackbar";
import {fetchUser} from "@/util/user-util";

export default {
  name: 'App',
  components: {
    Vue3Snackbar
  },

  watch: {
    $route: {
      immediate: true,
      async handler() {
        await this.redirect()
      }
    }
  },

  methods: {
    fetchUser,
    async redirect() {
      try {
        await this.fetchUser()
      } catch (error) {
        // ignore
      }
    }
  }
}
</script>

<template>
  <teleport to="body">
    <Vue3Snackbar :duration="4000" bottom top />
  </teleport>

  <router-view/>
</template>