export default [
    {
        path: "/",
        name: "/",
        redirect: "/login",
    },
    {
        path: "/order/:orderId",
        name: "Order",
        component: () => import("@/views/pages/Order.vue")
    },
    {
        path: "/register",
        name: "Rejestracja",
        component: () => import("@/views/pages/account/Register.vue")
    },
    {
        path: "/login",
        name: "Logowanie",
        component: () => import("@/views/pages/account/Login.vue")
    },
    {
        path: "/profile",
        name: "Profil",
        component: () => import("@/views/pages/account/Profile.vue")
    },
    {
        path: "/verify/:verificationStep",
        name: "Weryfikacja",
        component: () => import("@/views/pages/account/Verify.vue")
    },
    {
        path: '/error-404',
        name: 'Error-404',
        component: () => import('@/views/pages/utility/Error404')
    },
    {
        path: '/error-500',
        name: 'Error-500',
        component: () => import('@/views/pages/utility/Error500')
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () => import('@/views/pages/utility/Maintenance.vue')
    },
    {
        path: "/:catchAll(.*)",
        name: 'error-404',
        redirect: '/error-404',
    }
]