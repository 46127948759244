import axios from "axios";
import {useUserStore} from "@/store/user";
import {catchErrors, getError} from "@/util/error-catcher";

async function fetchUser() {
    const userStore = useUserStore();

    try {
        const {data} = await axios.get(`/user`)
        userStore.setUser(data)

        if (this.$route.path === "/register" || this.$route.path === "/login") {
            this.$router.push("/profile")
        }
    } catch (error) {
        userStore.setUser(null)

        const ecatError = getError(error)
        if (ecatError) {
            if (ecatError.type === "AuthenticationErrors" && (ecatError.code === 2 || ecatError.code === 3)) {
                this.$router.push(`/verify/${ecatError.code === 2 ? 'PHONE_NUMBER' : 'EMAIL'}`)
            }
        }

        throw error
    }
}

async function logout() {
    try {
        await axios.delete("/user/session/delete-current")
        if (this.$route.path !== "/") {
            await this.$router.push("/")
        }

        await useUserStore().setUser(null)
    } catch (error) {
        catchErrors(error)
    }
}

export { fetchUser, logout }