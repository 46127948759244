<script>
export default {
  name: "Checkbox",
  props: {
    label: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isChecked: this.checked,
    }
  },

  watch: {
    checked: {
      handler: function (value) {
        this.isChecked = value;
      },
      immediate: true
    }
  },

  computed: {
    getDataCheckbox() {
      return this.isChecked ? 'checked' : 'default';
    },

    getCheckboxType() {
      return this.circle ? 'circle' : 'square';
    },

    getIcon() {
      return this.circle ? 'circle.svg' : 'square.svg';
    }
  },

  methods: {
    changeStatus() {
      this.isChecked = !this.isChecked;
    }
  }
};
</script>

<template>
  <div class="custom-checkbox d-flex align-items-start"
       :data-checkbox="getDataCheckbox">
    <div class="custom-checkbox-indicator d-flex flex-row justify-content-center align-items-center" :data-checkbox-type="getCheckboxType">
      <img class="checkbox-icon" :src="require(`@/assets/icons/checkbox/${getIcon}`)" alt="Checkbox icon"
           :style="`display: ${isChecked ? 'block' : 'none'}`">
    </div>
    <div class="custom-checkbox-title">
      <slot name="body"/>
      {{ label }}
    </div>
  </div>
</template>

<style scoped>
[data-checkbox="default"] {
  [data-checkbox-type="circle"] {
    --checkbox-bg: transparent;
    --checkbox-border: rgba(106, 114, 123, 0.5);
    --checkbox-title: rgba(106, 114, 123, 1);
    --checkbox-radius: 1px;
    --checkbox-bg-hover: transparent;
    --checkbox-border-hover: rgba(106, 114, 123, 0.5);
    --checkbox-title-hover: rgba(106, 114, 123, 0.5);
    --checkbox-radius-hover: 2px;
    --checkbox-border-radius: 50% !important;
  }

  [data-checkbox-type="square"] {
    --checkbox-bg: transparent;
    --checkbox-border: rgba(106, 114, 123, 0.5);
    --checkbox-title: rgba(106, 114, 123, 1);
    --checkbox-radius: 1px;
    --checkbox-bg-hover: transparent;
    --checkbox-border-hover: rgba(106, 114, 123, 0.5);
    --checkbox-title-hover: rgba(106, 114, 123, 0.5);
    --checkbox-radius-hover: 2px;
    --checkbox-border-radius: 2px !important;
  }
}

[data-checkbox="checked"] {
  [data-checkbox-type="circle"] {
    --checkbox-bg: transparent;
    --checkbox-border: rgba(37, 132, 235, 1);
    --checkbox-title: rgba(37, 132, 235, 1);
    --checkbox-radius: 1px;
    --checkbox-bg-hover: transparent;
    --checkbox-border-hover: rgba(37, 132, 235, 1);
    --checkbox-title-hover: rgba(37, 132, 235, 1);
    --checkbox-radius-hover: 2px;
    --checkbox-border-radius: 50% !important;
  }

  [data-checkbox-type="square"] {
    --checkbox-bg: rgba(72, 146, 241, 1);
    --checkbox-border: none;
    --checkbox-title: rgba(37, 132, 235, 1);
    --checkbox-radius: 1px;
    --checkbox-bg-hover: rgba(72, 146, 241, 1);
    --checkbox-border-hover: rgba(37, 132, 235, 1);
    --checkbox-title-hover: rgba(37, 132, 235, 1);
    --checkbox-radius-hover: 2px;
    --checkbox-border-radius: 2px !important;
  }
}

.custom-checkbox {
  line-height: normal;
}

.custom-checkbox:hover {
  .custom-checkbox-indicator {
    border-width: var(--checkbox-radius-hover) !important;
    border-color: var(--checkbox-border-hover);
    background: var(--checkbox-bg-hover);
  }

  .custom-checkbox-title {
    color: var(--checkbox-title-hover) !important;
  }
}

.checkbox-circle .checkbox-icon {
  width: 9px;
  height: 9px;
}

.custom-checkbox-indicator {
  min-width: 15px;
  min-height: 15px;

  background: var(--checkbox-bg);
  border: var(--checkbox-radius) solid var(--checkbox-border);
  border-radius: var(--checkbox-border-radius);

  transition: all 0.3s ease;
}

.checkbox-icon {
  width: 9px;
  height: 9px;
}

.custom-checkbox-title {
  font-size: 12px;
  font-weight: normal;
  padding-left: 5px;
  color: var(--checkbox-title);
}
</style>
