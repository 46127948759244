import router from "@/router";
import App from "@/App.vue";
import {createApp} from "vue";
import axios from "axios";
import { SnackbarService } from "vue3-snackbar";
import {createPinia} from "pinia";
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'

import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import Checkbox from "@/components/Checkbox.vue";
import Input from "@/components/Input.vue";

import "vue3-snackbar/styles";
import '@/assets/css/font.css'
import '@/assets/scss/bootstrap.scss'
import CustomCard from "@/components/card/CustomCard.vue";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common = {
    "Content-Type": "application/json",
    "Accept": "application/json"
}

axios.defaults.transformRequest = [(data) => {
    if (data instanceof FormData) {
        return data
    }

    if (!data) {
        data = {}
    }

    return JSON.stringify(data);
}];

const app = createApp(App);
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

app.use(router);
app.use(pinia)
app.use(SnackbarService);
app.component("Button", Button)
app.component("Modal", Modal)
app.component("Input", Input)
app.component("Checkbox", Checkbox)
app.component("CustomCard", CustomCard)
app.mount("#app");