<script>
export default {
  name: "Button",
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default'
    },
    buttonClass: {
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<template>
  <div>
    <div :data-button="type" class="button d-flex align-items-center justify-content-center" :class="buttonClass" @click="$emit('button-click')">
      <img src="@/assets/icons/user.svg" alt="User Icon" v-if="showIcon" class="pe-1">
      {{ title }}
    </div>
  </div>
</template>

<style scoped>
[data-button="default"] {
  --button-bg: transparent;
  --button-border: rgba(106, 114, 123, 1);
  --button: rgba(106, 114, 123, 1);
  --button-bg-hover: transparent;
  --button-border-hover: rgba(106, 114, 123, 1);
  --button-hover: rgba(106, 114, 123, 1);
  --font-size: 12px;
  --font-weight: 700;
}

[data-button="blue"] {
  --button-bg: linear-gradient(0deg, rgba(20, 112, 229, 1) 0%, rgba(54, 152, 242, 1) 100%);
  --button-border: none;
  --button: #fff;
  --button-bg-hover: linear-gradient(0deg, rgba(65, 147, 252, 1) 0%, rgba(100, 181, 255, 1) 100%);
  --button-border-hover: none;
  --button-hover: #fff;
  --font-size: 12px;
  --font-weight: 700;
}

[data-button="blue-border"] {
  --button-bg: transparent;
  --button-border: rgba(37, 132, 235, 1);
  --button: rgba(37, 132, 235, 1);
  --button-bg-hover: rgba(37, 132, 235, 0.1);
  --button-border-hover: rgba(37, 132, 235, 1);
  --button-hover: rgba(37, 132, 235, 1);
  --font-size: 14px;
  --font-weight: 500;
}

[data-button="red-border"] {
  --button-bg: transparent;
  --button-border: rgb(235, 37, 37);
  --button: rgb(235, 37, 37);
  --button-bg-hover: rgba(37, 132, 235, 0.1);
  --button-border-hover: rgb(235, 37, 37);
  --button-hover: rgb(235, 37, 37);
  --font-size: 14px;
  --font-weight: 500;
}

.button {
  font-size: var(--font-size);
  font-weight: var(--font-weight);

  color: var(--button);

  height: 40px;

  border: 1px solid var(--button-border);
  border-radius: 5px;

  background: var(--button-bg);

  padding: 0 25px;

  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  transition: all 0.3s ease-in-out;
  background: var(--button-bg-hover) border-box;
}

.button:not(:hover) {
  transition: all 0.3s ease-in-out;
}

</style>
